<template>
  <div 
    class="zhuantiList_phone"
    id="fangtan_phone"
    v-infinite-scroll="getData"
    infinite-scroll-distance="10"
    infinite-scroll-disabled="busy">

    <!-- 面包屑 -->
    <el-row type="flex" justify="center" class="crumbs">
      <el-col >
        <el-breadcrumb separator=">">
          <el-breadcrumb-item :to="{ path: '/' }">社科视频首页</el-breadcrumb-item>
          <el-breadcrumb-item>专题</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>

    <div class="container">
      <el-row type="flex" justify="center">
        <el-col >

          <!-- 副标题 -->
          <ViceHeadline :title="'专题'" />

          <div class="container">
      <el-row type="flex" justify="center">
        <el-col >

          <el-row :gutter="$store.state.isEquipment == 'pc'?20:0">
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" v-for="item in list" :key="item.index">
              <div class="card">
                <img  v-lazy="item.pub_cover" alt="" />
                <div class="card_content">
                  <p>{{ item.SYS_TOPIC }}</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
        </el-col>
      </el-row>
    </div>

    <div class="loading">
      <p class="busy" v-show="busy">加载中...</p>
      <p class="finish" v-show="finish">没有更多了</p>
    </div>
  </div>
</template>

<script>
import ViceHeadline from "@/components/ListHeadline.vue";
import api from '@/api/index.js'
export default {
  props: {
    msg: String,
  },
  components: {
    ViceHeadline,
  },
  data() {
    return {
      pageNo: 1,
      busy: false,
      finish: false,
      disabled: false,
      list: [],
    };
  },

  methods: {
    load() {
      console.log("load")
      var slef = this;
      if (this.pageNo == 1) {
        slef.getData();
      } else {
        this.busy = true;
        setTimeout(function () {
          slef.getData();
        }, 1000);
      }
    },
    getData() {
      if(this.finish){
        return;
      }
      this.busy = true;
      api
        .post("spc/cms/publish/queryList.do", {
          s: 2,
          c: 85,
          pageNo: this.pageNo,
          pageSize: 12,
        })
        .then((res) => {
          console.log(res.result)
          for (var item in res.result) {
            this.list.push(res.result[item]);
          }
          this.busy = false;
          if (res.result.length == 0) {
            this.finish = true;
          } else {
            this.pageNo += 1;
          }
        });
    },
  },
};
</script>
<style scoped lang="scss">
h3 {
  margin: 2.5rem 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 .625rem;
}
a {
  color: #42b983;
}
.zhuantiList_phone {
  background: #f9f9f9;

  .crumbs {
    padding-top: 1.25rem;
  }

  .container {
    .card {
      margin-top: 1.5rem;
      padding: 1rem;
      background: #ffffff;
      box-shadow: 0px 0px 13px 0px rgba(183, 183, 183, 0.38);
      // width: 20rem;
      // height: 21rem;
      cursor: pointer;
      img {
        width: 100%;
        // height: 12.5rem;
      }
      .card_content {
        // margin-top: 3rem;
        padding: 0.75rem 0 0 0;
        height: 4.5rem;
        text-align: center;
        p {
          font-size: 1.25rem;
          font-weight: bold;
          color: #333333;
          line-height: 2.25rem;
          display: -webkit-box;
          overflow: hidden;
          white-space: normal !important;
          text-overflow: ellipsis;
          word-wrap: break-word;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  .loading {
    margin: 0.5rem 0;
    p {
      line-height: 2rem;
      font-size: 1rem;
      text-align: center;
      color: #5e6d82;
    }
  }
}
</style>

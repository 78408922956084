<template>
  <div class="zhuantiList">
    <div class="mainWidth">
      <!-- 面包屑 -->
      <el-row type="flex" justify="center" class="crumbs">
        <el-col>
          <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }"
              >社科视频首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>专题</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>

      <div class="container">
        <el-row type="flex" justify="center">
          <el-col>
            <!-- 副标题 -->
            <ViceHeadline title="专题" />

            <el-row :gutter="$store.state.isEquipment == 'pc' ? 20 : 0">
              <el-col
                :xs="24"
                :sm="6"
                :md="6"
                :lg="6"
                :xl="6"
                v-for="item in list"
                :key="item.index"
              >
                <a
                  class="card"
                  target="_blank"
                  :href="item.XINWENZIXUN_LINK_ADDRESS"
                >
                  <img v-lazy="item.pub_cover" alt="" />
                  <div class="card_content">
                    <p>{{ item.SYS_TOPIC }}</p>
                  </div>
                </a>
              </el-col>
            </el-row>

            <!-- 分页 -->
            <Paging :value="value" @transfer="getData" />
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import ViceHeadline from "@/components/ListHeadline.vue";
import Paging from "@/components/Paging.vue";

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  components: {
    ViceHeadline,
    Paging,
  },
  data() {
    return {
      list: [],
      value: {
        path: "85",
        amount: "12",
      },
    };
  },
  methods: {
    getData(msg) {
      this.list = msg;
    },
  },
};
</script>
<style lang='scss' scoped>
.zhuantiList {
  background: #f9f9f9;
  // padding-bottom: 1.875rem;

  .crumbs {
    padding-top: 1.25rem;
  }

  .container {
    .card {
      display: block;
      margin-top: 1.5rem;
      padding: 1.25rem 1.25rem 0.5rem 1.25rem;
      background: #ffffff;
      box-shadow: 0px 0px 13px 0px rgba(183, 183, 183, 0.38);
      // width: 20rem;
      // height: 21rem;
      cursor: pointer;

      img {
        width: 100%;
        aspect-ratio: 4/3;
        height: 221.25px;
        display: block;
        // height: 11.0625rem;
        // height: 12.5rem;
      }

      .card_content {
        // margin-top: 3rem;
        padding: 1rem;
        // height: 3rem;
        text-align: center;

        p {
          font-size: 1.25rem;
          font-weight: bold;
          color: #333333;
          line-height: 2.25rem;
          display: -webkit-box;
          overflow: hidden;
          white-space: normal !important;
          text-overflow: ellipsis;
          word-wrap: break-word;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          height: 4.5rem;
        }
      }
    }
  }
}
@media screen and(max-width:1440px) {
  .zhuantiList .container .card img {
    height: 150px;
  }
}
</style>